import styled from 'styled-components'

import * as colors from '../config/theme/colors'
import media from '../config/theme/media'

import Spinner from 'components/Spinner'
import Span from './Typography/Span'

const StyledButton = styled.button`
  display: flex;
  background-color: ${({ inverse }) => inverse ? colors.buttonBgColorSecondary : colors.buttonBgColorPrimary};
  ${({ disabled }) => disabled ? `opacity: 0.7;cursor: default;` : 'cursor: pointer;'};
  height: 40px;
  padding: 0 40px;
  box-shadow: 0 1px 0 0 rgba(0,0,0,0.2);
  border: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  &:hover {
    background-color: ${({ inverse }) => inverse ? colors.buttonHoverColorSecondary : colors.buttonHoverColorPrimary};
    color: ${colors.black()};
  }

  ${({ fullWidth }) => fullWidth ? 'width: 100%;' : ''}

  ${media.lessThan('md')`
    height: 30px;
    padding: 0 20px;
  `}
`

const StyledText = styled(Span)`
  margin: 0;
  padding: 0;
  font-weight: bold;
  letter-spacing: 0;
  display: flex;
`

const SpinnerContainer = styled.div`
  height: 20px;
  width: 20px;
  margin-left: 10px;
`

const Button = ({
  children,
  disabled,
  isLoading,
  inverse,
  bold,
  large,
  small,
  asComponent = 'button',
  ...props
}) => {
  return (
    <StyledButton as={asComponent} disabled={disabled || isLoading} inverse={inverse} {...props}>
      <StyledText inverse={!inverse} bold={bold || true} large={large || true} small={small}>{children}</StyledText>
      {
        isLoading && (
          <SpinnerContainer>
            <Spinner size={20} />
          </SpinnerContainer>
        )
      }
    </StyledButton>
  )
}

export default Button


const StyledButtonUrl = styled.button`
  background-color: transparent;
  border: 0;
  padding: 0;
`

const StyledButtonUrlText = styled(Span)`
  margin: 0;
  padding: 0;
  font-weight: ${({ bold }) => bold ? 'bold' : 'normal'}; ;
  letter-spacing: 0;
  color: ${({ disabled }) => disabled ? colors.black() : colors.hyperlink()};
  text-decoration: underline;
  ${({ disabled }) => disabled ? `opacity: 0.7; cursor: default` : 'cursor: pointer'};
  &:hover, &:active, &:focus {
    color: ${colors.black()};
  }
`

export const ButtonUrl = ({
  children,
  disabled,
  isLoading,
  bold,
  ...props
}) => {
  return (
    <StyledButtonUrl disabled={isLoading} {...props}>
      <StyledButtonUrlText disabled={isLoading} bold={bold}>{children}</StyledButtonUrlText>
    </StyledButtonUrl>
  )
}
