import Link from 'components/Link'
import { ButtonUrl } from 'components/Button'
import _ from 'lodash'

const Url = ({ text, url, target, onClick, isLoading }) => {
  const [beforeLink, linkAndAfterLink] = _.split(text, '<link>')
  const [link, afterLink] = _.split(linkAndAfterLink, '</link>')
  return (
    <>
      {beforeLink}
      {link && url && (
        <Link style={{ whiteSpace: 'nowrap' }} href={url} target={target}>
          {link}
        </Link>
      )}
      {link && onClick && (
        <ButtonUrl style={{ whiteSpace: 'nowrap' }} onClick={onClick} isLoading={isLoading}>
          {link}
        </ButtonUrl>
      )}
      {afterLink}
    </>
  )
}

export default Url
